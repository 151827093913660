<template>
  <div>
    <div class="text-left">
      <div class="d-flex justify-content-between">
        <h3 class="f-bold cl-primary mb-4">Submit Packages</h3>
        <p
          class="cl-primary align-self-center"
          v-on:click="toList()"
          style="cursor: pointer"
        >
          List Packages
        </p>
      </div>
      <form @submit.prevent="submit">
        <div class="row">
          <div class="col-md-6">
            <label for="exampleFormControlTextarea1" class="text-secondary">
              Shipping Address</label
            >
            <textarea
              v-model="form.shipping_address"
              class="form-control"
              id="exampleFormControlTextarea1"
              rows="3"
            ></textarea>
          </div>
          <div class="col-md-6">
            <label for="exampleFormControlTextarea1" class="text-secondary">
              Description</label
            >
            <textarea
              v-model="form.description"
              class="form-control"
              id="exampleFormControlTextarea1"
              rows="3"
            ></textarea>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-md-6">
            <div class="form-group">
              <label for="packing_list" class="text-secondary"
                >Upload Packing List (PDF)</label
              >
              <input
                type="file"
                class="form-control-file"
                id="packing_list"
                ref="packingList"
                @change="handeUploadPackingList()"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="invoices" class="text-secondary"
                >Upload Invoice (PDF)</label
              >
              <input
                type="file"
                @change="handleUploadInvoice()"
                class="form-control-file"
                ref="invoices"
                id="invoices"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6">
            <div class="form-group">
              <label for="code">Promotion Code</label>
              <input
                type="text"
                class="form-control b-radius px-4"
                id="promotion_code"
                aria-describedby="PromotionCodeHelp"
                placeholder="Enter Promotion Code"
                v-model="form.promotion_code"
              />
              <!-- <small id="emailHelp" class="form-text text-muted">We'll never share your email with
                                    anyone else.</small> -->
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-end" v-if="!loading">
          <button type="submit" class="btn btn-primary px-sm-5">
            Submit Packages
          </button>
        </div>
        <div class="d-flex justify-content-end" v-if="loading">
          <loader-component></loader-component>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import LoaderComponent from "../../../components/Loader.vue";
export default {
  components: { LoaderComponent },
  props: ["promotion_code"],
  data() {
    return {
      loading: false,
      form: {
        shipping_address: "",
        description: "",
        invoice: "",
        package_list: "",
        promotion_code: "",
      },
    };
  },
  mounted() {
    if (this.promotion_code) {
      this.form.promotion_code = this.promotion_code;
    } else {
      this.form.promotion_code = "";
    }
    console.log(this.form.promotion_code);
  },
  computed: {
    userLogin: function () {
      return this.$store.getters.getProfileCustomers;
    },
  },
  methods: {
    handeUploadPackingList() {
      this.form.package_list = this.$refs.packingList.files[0];
      console.log(this.form.package_list);
    },

    handleUploadInvoice() {
      this.form.invoice = this.$refs.invoices.files[0];
      console.log(this.form.invoice);
    },

    resetForm() {
      (this.form.shipping_address = ""),
        (this.form.description = ""),
        (this.form.invoice = null),
        (this.form.package_list = null);
    },

    submit: function () {
      Vue.swal({
        title: "Are you sure?",
        text: "Your data will be entered into our system",
        icon: "question",
        showCancelButton: true,
        cancelButtonColor: "#eb4d4b",
        confirmButtonText: "Yes, Submit Packages!",
      }).then((result) => {
        if (result.isConfirmed) {
          let formData = new FormData();
          this.loading = true;
          formData.append("shipping_address", this.form.shipping_address);
          formData.append("description", this.form.description);
          formData.append("package_list", this.form.package_list);
          formData.append("invoice", this.form.invoice);
          formData.append("promotion_code", this.form.promotion_code);
          this.$store
            .dispatch("postPackages", formData)
            .then((res) => {
              if (res.data.status == "Error") {
                this.loading = false;
                Vue.swal(res.data.message, res.data.data, "error");
              } else {
                this.loading = false;
                console.log(res);
                this.resetForm();
                Vue.swal(
                  res.data.status,
                  "Your data is already entered in our system",
                  "success"
                ).then(() => {
                  this.toList();
                });
              }
            })
            .catch((err) => {
              let errorMsg = "";
              this.loading = false;
              Object.keys(err.response.data).forEach(function (key) {
                let errorItem = key.charAt(0).toUpperCase() + key.slice(1);
                let errorCause = "";
                let errorLists = err.response.data[key];
                for (var prop in errorLists) {
                  errorCause += "<li>" + errorLists[prop] + "</li>";
                }
                errorMsg +=
                  "<ul style='list-style: none; text-align: left;' class='text-left'><b>" +
                  errorItem +
                  "</b>" +
                  errorCause +
                  "</ul><br>";
              });

              Vue.swal({
                icon: "error",
                title: "Error!",
                html: errorMsg,
              });
              console.log(err.response.data);
            });
        }
      });
    },

    toList() {
      this.$router.push("/customers/my-packages");
    },
  },
};
</script>
